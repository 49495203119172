<template>
	<div class="main">
         <TheTopbar/>
         <slot />
	<div class="main-container">
    <div class="d-flex">
      <div class="content-wrapper">
        
          <div class="d-flex justify-content-between modal-header m-3"> 
                <h5 class="text-white">SYSTEM SETTINGS</h5>
               
            </div>
          <div class="options">
                  <button class="btn btn-main "  :class="{ active: state == 'rulehost'}" @click="changeState('rulehost')" >RULEHOST </button>
                  <button class="btn btn-main"  :class="{ active: state == 'czrole'}" @click="changeState('czrole')">ROLES </button>
                  <button class="btn btn-main"  :class="{ active: state == 'czfxn'}" @click="changeState('czfxn')">FUNCTIONS </button>
                  <button class="btn btn-main"  :class="{ active: state == 'email'}" @click="changeState('email')">EMAIL </button>
                  <button class="btn btn-main"  :class="{ active: state == 'sms'}" @click="changeState('sms')">SMS </button>
                  <button class="btn btn-main"  :class="{ active: state == 'sys'}" @click="changeState('sys')">SYS </button>
                  <button class="btn btn-main"  :class="{ active: state == 'company'}" @click="changeState('company')">COMPANIES </button>
                
          </div>

      


        <div class="modal-body" v-if="section == 'init'">
          <button class="btn btn-light" @click="showAdd()">add</button>
              <div class="market-content">
                
                <div class="market-content">
                  <table class="table" v-if="state != 'company'">
                    <thead>
                      <tr>
                          <th>Reference Type</th>
                          <th>	Reference Code</th>
                          <th>	Reference Value</th>
                          <th></th>
                      </tr>
                    </thead>
                  
                      <tbody v-for="item in refDataResponses" :key="item.id" >
                          <td>{{ item.refType }}</td>
                          <td>{{ item.refCode }}</td>
                          <td>{{ item.refValue }}</td>
                          <td><button @click="showEdit(item)" class="btn btn-light">edit</button> |
                          <button @click="showDelete(item)" class="btn btn-light">delete</button> </td>
                    </tbody>
                  </table>

                  <table class="table" v-if="state == 'company'">
                    <thead>
                      <tr>
                        <th>Company ID:</th>
                        <th>Company Name:</th>
                        <th>Last Update:</th>
                        <th>Last Update By:</th>
                      </tr>
                    </thead>
                    <tbody v-for="item in cezaOnly" :key="item.id">
                      <td>{{ item.companyId }}</td>
                      <td>{{ item.companyName }}</td>
                      <td>{{ item.lastUpdate }}</td>
                      <td>{{ item.lastUpdateBy }}</td>
                    </tbody>
                  </table>

                 
                  </div>
                
                  </div>
                  <button class="btn btn-success"  style="float: right;" @click="refresh(state)">REFRESH </button>
                  <br>
        </div>

        
        <div class="market-content mt-5" > 
         
        </div>
    

        <div v-if="state != 'company'">
          <div v-if="section == 'edit'" class="modal-body"> 
            <div   class="market-content mt-5"> 
                    <p v-if="errorShow" style="color:pink;">{{ errorMsg }}</p>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Reference Type:</th>
                          <th>Reference Type:</th>
                          <th>Reference Type:</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <td>{{ item.refType }}</td>
                        <td>{{ item.refCode }}</td>
                        <td><input v-model="item.refValue"/> </td>
                        <td><button @click="editItem(item)" class="btn btn-light">update</button></td>
                      </tbody>
                    </table>
              </div>
            </div>


            <div class="modal-body" v-if="section == 'delete'">
            <p v-if="errorShow" style="color:pink;">{{ errorMsg }}</p>
            <div   class="market-content mt-5"> 
              <h5 class="text-white">Are you sure you want to delete reference data {{item.refType}} - {{item.refCode}}</h5>     
              
              <div> 
                <button @click="cancel()" class="btn btn-light">cancel</button>
                <button @click="deleteItem(item)" class="btn btn-light">confirm</button>
              </div>
            </div>
          </div>


          <div class="modal-body" v-if="section == 'add'">
            <p v-if="errorShow" style="color:pink;">{{ errorMsg }}</p>
            <div   class="market-content mt-5"> 
              <h5 class="text-white">Add new code - {{state}}</h5>
              <div class="form-group"> 
                <label>Reference Type:</label>
                <input v-model="state" disabled class="form-control"/>
              </div>
              <div class="form-group"> 
                <label>Reference Code:</label>
              <input v-model="refCode" class="form-control"/>
              </div>
              <div class="form-group"> 
                <label>Reference Value:</label>
              <input v-model="refValue" class="form-control"/>
              </div>
              <div> 
                <button @click="cancel()" class="btn btn-light">cancel</button>
                <button @click="addItem()" class="btn btn-light">add</button>
              </div>
            </div>
            
          </div>



        </div>
      
        <div v-if="state == 'company'">
          <div v-if="section == 'edit'" class="modal-body"> 
            <div   class="market-content mt-5"> 
                    <p v-if="errorShow" style="color:pink;">{{ errorMsg }}</p>
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Reference Type:</th>
                          <th>Reference Type:</th>
                          <th>Reference Type:</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <td>{{ item.refType }}</td>
                        <td>{{ item.refCode }}</td>
                        <td><input v-model="item.refValue"/> </td>
                        <td><button @click="editItem(item)" class="btn btn-light">update</button></td>
                      </tbody>
                    </table>
              </div>
            </div>


            <div class="modal-body" v-if="section == 'delete'">
            <p v-if="errorShow" style="color:pink;">{{ errorMsg }}</p>
            <div   class="market-content mt-5"> 
              <h5 class="text-white">Are you sure you want to delete reference data {{item.refType}} - {{item.refCode}}</h5>     
              
              <div> 
                <button @click="cancel()" class="btn btn-light">cancel</button>
                <button @click="deleteItem(item)" class="btn btn-light">confirm</button>
              </div>
            </div>
          </div>


          <div class="modal-body" v-if="section == 'add'">
            <p v-if="errorShow" style="color:pink;">{{ errorMsg }}</p>


            <div   class="market-content mt-5"> 
              <h5 class="text-white">Add new - {{state}}</h5>
                <div class="form-group"> 
                  <label>Company Name:</label>
                  <input v-model="companyName" class="form-control"/>
                </div>
                <div class="form-group"> 
                  <label>Base Currency:</label>
                <input v-model="baseCurrency" class="form-control"/>
                </div>
                <div class="form-group"> 
                  <label>Allow Negative Balance:</label>
                
                    <input type="checkbox" id="agreed" name="agreed" value="true" v-model="allowNegativeBal">
                    <label for="agreed" class="p-1">YES</label>
              
                      <input type="checkbox" id="disagreed" name="disagreed" value="false" v-model="allowNegativeBal">
                      <label for="unchecked" class="p-1">NO</label>
                </div>
              
              
              <div class="form-group"> 
                <label>SMS on Customer Activation:</label>
                <input type="checkbox" id="agreed" name="agreed" value="true" v-model="smsActivationEnabled">
                  <label for="agreed" class="p-1">YES</label>
            
              </div>
              <div class="form-group"> 
                <label>SMS on Customer Notifcation:</label>
                <input type="checkbox" id="agreed" name="agreed"  v-model="smsNotificationEnabled">
                  <label for="agreed" class="p-1">YES</label>
            
                  
              </div>
              <div> 
                <button @click="cancel()" class="btn btn-light">cancel</button>
                <button @click="addItem()" class="btn btn-light">add</button>
              </div>
            </div>
          </div>
        </div>


        </div>
      
       

       
      </div>
    </div>
    </div>

  
 
          
</template>
         
<script>
  import moment from 'moment';
  import TheTopbar from '../components/TheTopbar.vue'
  import Header from '../components/Header.vue'
 

  export default {
      name: 'TransactionSearch',
      components: {
        TheTopbar,
        Header,
         
      },
      
       data: function() {
        const today = new Date();
        return { 
          refDataResponses:[],
          state:'rulehost',
          item:{},
          editSection:false,
          errorShow: false,
          errorMsg:'',
          section:'init',
          refCode:'',
          refValue:'',
          companyLists:[],
          smsActivationEnabled: false,
          smsNotificationEnabled: false,
          baseCurrency: '',
          companyId:'',
          companyName:''
        }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        cezaOnly(){
          return this.companyLists.filter(item => item.entityName === 'CEZA')
        }
       
      },
      methods:{
      
        goHome(){
          window.location.href="/#/customer-search";
        },
        goBack(){
          this.$router.go(-1);
        },
      
        changeState(state) {
            this.state = state;
            localStorage.setItem("state", state);
            this.getRefData();
          },
        getState() {
            let state = localStorage.getItem("state")
            if(state != "" || state != undefined) {
            this.state = state
            }
        },
        showAdd(){
          this.section = 'add'
        },
        showEdit(item){
          this.item = item
          this.section = 'edit'
        },
        showDelete(item){
          this.item = item
          this.section = 'delete'
        },
        cancel(){
          this.section = 'init'
        },
          formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
        getCompanyList(){
          
            const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                         
                          url: '/ax/listCompanies',
                      
                          };
                  this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                          this.companyLists = response.data
                            
                          }).catch((err) => { 
                      })
        },
         
          getRefData(){
            const params = {
              refType: this.state
            }
                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                         
                          url: '/ax/getRefDataList',
                          params
                          };
                      this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                          this.refDataResponses = response.data
                            
                          }).catch((err) => { 
                      })
          },
          editItem(item){
            this.item = item
            const params = {
              refType: this.item.refType,
              refCode: this.item.refCode,
              refValue: this.item.refValue 
            }
              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                url: '/ax/updateRefData',
                params
              };
                  this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                            this.errorShow = true
                            this.errorMsg = 'Successfully updated.'
                            setTimeout(()=>{
                              this.errorShow = false
                            this.errorMsg = ''
                              this.section = 'init'
                            this.getRefData()
                            }, 2000)
                          }).catch((err) => { 
                            this.errorShow = true
                            this.errorMsg = err.response.data.msgText
                            setTimeout(()=>{
                            this.errorMsg = "";
                            this.errorShow = false
                            }, 3000)
                      })
          },
          deleteItem(item){
            this.item = item
            const params = {
              refType: this.item.refType,
              refCode: this.item.refCode,
            }
              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                url: '/ax/deleteRefData',
                params
              };
                  this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                            this.errorShow = true
                            this.errorMsg = 'Successfully updated.'
                            setTimeout(()=>{
                              this.errorShow = false
                            this.errorMsg = ''
                              this.section = 'init'
                            this.getRefData()
                            }, 2000)
                          }).catch((err) => { 
                            this.errorShow = true
                            this.errorMsg = err.response.data.msgText
                            setTimeout(()=>{
                            this.errorMsg = "";
                            this.errorShow = false
                            }, 3000)
                      })
          },
          addItem(){
            const params = {
              refType: this.state,
              refCode: this.refCode,
              refValue: this.refValue
            }
              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                url: '/ax/createRefData',
                params
              };
                  this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                            this.errorShow = true
                            this.errorMsg = 'Successfully updated.'
                            setTimeout(()=>{
                              this.errorShow = false
                            this.errorMsg = ''
                            this.section = 'init'
                            this.getRefData()
                            }, 2000)
                          }).catch((err) => { 
                            this.errorShow = true
                            this.errorMsg = err.response.data.msgText
                            setTimeout(()=>{
                            this.errorMsg = "";
                            this.errorShow = false
                            }, 3000)
                      })
          },
          addCompany(){
            const params = {
              companyId: this.companyId,
              companyName: this.companyName,
              baseCurrency: this.baseCurrency,
              allowNegativeBal: this.allowNegativeBal,
              smsActivationEnabled: this.smsActivationEnabled,
              smsNotificationEnabled: this.smsNotificationEnabled
            }
              const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                url: '/ax/addCompany',
                params
              };
                  this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                            this.errorShow = true
                            this.errorMsg = 'Successfully updated.'
                            setTimeout(()=>{
                              this.errorShow = false
                              this.errorMsg = ''
                              this.section = 'init'
                              this.getCompanyList()
                            }, 2000)
                          }).catch((err) => { 
                            this.errorShow = true
                            this.errorMsg = err.response.data.msgText
                            setTimeout(()=>{
                            this.errorMsg = "";
                            this.errorShow = false
                            }, 3000)
                      })
          },
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
             this.axios(options)
              .then((response) => {
              
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },
          refresh(state){

            const params = {
              beanId: this.state,
              refreshParam: this.state
            }
                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                         
                          url: '/ax/publishRefreshEvent',
                          params
                          };
                      this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                          
                            
                          }).catch((err) => { 
                      })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getState();
          this.getRefData();
          this.getCompanyList();
      }
  }
</script>
        
<style lang=scss scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
 .market-content {
  width: 100%;
      border-radius: 10px;
      padding: 0;
      max-height: 450px; /* Set your desired maximum height */
      overflow: auto;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    width: 100%;
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>